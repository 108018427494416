"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const paths_1 = require("@/common/app/paths");
const statusCode_1 = require("@/common/http/statusCode");
exports.default = vue_1.default.extend({
    name: 'login-home',
    data() {
        return {
            user: undefined,
        };
    },
    mounted() {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', 'profile');
        xhr.onerror = () => alert('Error getting session profile data');
        xhr.onload = () => {
            try {
                if (xhr.status === statusCode_1.statusCode.ok) {
                    this.user = xhr.response._user.userid;
                }
                else {
                    console.error('Unexpected server response: ' + xhr.statusText);
                }
            }
            catch (e) {
                console.log('Error processing XHR response: ' + e);
            }
        };
        xhr.responseType = 'json';
        xhr.send();
    },
    computed: {
        loginUrl() {
            return 'https://discord.com/oauth2/authorize?client_id=1326283152448163921&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fauth%2Fdiscord%2Fcallback&scope=identify';
        },
        logoutURL() {
            return paths_1.paths.API_LOGOUT;
        },
    },
});
