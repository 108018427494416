"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paths = void 0;
exports.paths = {
    ADMIN: 'admin',
    API_CLONEABLEGAME: 'api/cloneablegame',
    API_GAME: 'api/game',
    API_GAME_HISTORY: 'api/game/history',
    API_GAME_LOGS: 'api/game/logs',
    API_GAMES: 'api/games',
    API_IPS: 'api/ips',
    API_METRICS: 'api/metrics',
    API_LOGOUT: 'api/logout',
    API_PLAYER: 'api/player',
    API_PROFILE: 'api/profile',
    API_SPECTATOR: 'api/spectator',
    API_STATS: 'api/stats',
    API_WAITING_FOR: 'api/waitingfor',
    AUTOPASS: 'autopass',
    CARDS: 'cards',
    GAME: 'game',
    GAMES_OVERVIEW: 'games-overview',
    HELP: 'help',
    LOAD: 'load',
    LOAD_GAME: 'load_game',
    LOGIN: 'login',
    NEW_GAME: 'new-game',
    PLAYER: 'player',
    PLAYER_INPUT: 'player/input',
    RESET: 'reset',
    SPECTATOR: 'spectator',
    THE_END: 'the-end',
};
