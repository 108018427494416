"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statusCode = void 0;
exports.statusCode = {
    movedPermanently: 301,
    found: 302,
    seeOther: 303,
    notModified: 304,
    badRequest: 400,
    forbidden: 403,
    notFound: 404,
    tooManyRequests: 429,
    internalServerError: 500,
    ok: 200,
};
